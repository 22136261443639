<div class="pincing-section text-center" style="padding-bottom: 50px;">
  <div class="container">
    <div class="col-md-12 col-sm-12">
      <div class="row">
        <div class="col-sm-6" *ngFor="let plan of this.bpService.CurrentPlans.filter(activePlan).sort(orderPlan)">
          <div class="table-left wow fadeInUp" data-wow-delay="0.4s">
            <div class="pricing-details" style="cursor: pointer;" [ngClass]="{ 'planSelected':  (this.selectedPlanIdentifier === plan.identifier) }" (click)="selectPlan(plan.identifier)">
              <h2>{{ plan.name }}</h2>
              <span>${{ plan.detail.price }}/User(s)</span>
              <p>{{ plan.description }}</p>
              <div class="pricingTable">
                <table>
                  <tbody>
                  <tr *ngFor="let f of plan.features.filter(activeFeature).sort(orderFeature)">
                    <td>{{ f.feature.description }}</td>
                    <td class="state">
                      <i class="fa fa-check" style="color: green;" *ngIf="f.active && f.limit === null"></i>
                      <i class="feather icon-x" style="color: red;" *ngIf="!f.active"></i>
                      <div *ngIf="f.limit !== null && f.active">{{ f.limit }} {{ f.limitUnit }}</div>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
