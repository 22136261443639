<div style="overflow: scroll; height:350px; width:100%; border: 1px solid #ddd; padding: 10px;">
  <!-- Put this into a shared components -->
  <!-- Intro -->
  <div class="privacy-description">
    <p>
      Solutions Experta Inc. provides BudgetPlanner.Cloud (the "Service") and other related services subject to your compliance with the terms and conditions ("Terms of Service/TOS") set forth below.<br>
    </p>
    <p>
      Solutions Experta Inc. reserves the right to update and modify the TOS at any time without notice.&nbsp;
      Updates and Features that might be added to the Service will be subject to the TOS. Should you continue to use the Service after any modifications, this will constitute your agreement to such modifications.
    </p>
  </div>
  <div class="privacy-content">
    <!-- Point A -->
    <div>
      <div>
        <p>
          <b>A. Description of the services</b>
        </p>
        <div class="privacy-details">
          <p>
            BudgetPlanner.Cloud is a online small-business management service. Your use of the Service is your sole risk. The service is provided on "as is" and "as available" basis, however Experta Solutions Inc. will do all it can to provide you with a 99.90% uptime (<b>SLA</b>) of the service.
          </p>
        </div>
      </div>
    </div>
    <!-- Point B -->
    <div>
      <p><b>B.&nbsp; Eligibility</b></p>
      <div class="privacy-details">
        <p>
          You must be at least 18 years old to use this Service. You must provide a valid email address, and any other required information to complete the sign-up process.
        </p>
      </div>
    </div>
    <!-- Point C -->
    <div>
      <p><b>C.&nbsp;Payment Terms</b></p>
      <div class="privacy-details">
        <p>
          In case your company requires a paid subscription plan, you need a valid credit card to use the service.
          The service is billed in advance on a monthly or yearly basis and is non-refundable.
          There will be no refunds or credits for partial months of service, upgrade/downgrade accounts, or for months unused with an open account.
          All fees are exclusive of all taxes or duties imposed by governing authorities.
          You alone are responsible for payment of all such taxes or duties.
          In case of an upgrade or a change of your subscription plan your credit card will be charged on the same day with the new amount (according the the newly selected plan) for the remaining days in your billing cycle (the number of days between the day when the change has been made and date that you initially set up your subscription).
        </p>
      </div>
    </div>
    <!-- Point D -->
    <div>
      <p><b>D.&nbsp;Modification to fees and Services</b></p>
      <div class="privacy-details">
        <p>Solutions Experta Inc. reserves the right to change Service fees upon 30 days notice from us. Solutions Experta Inc. reserves the right to discontinue the Service of any account upon 30 days notice by us.</p>
      </div>
    </div>
    <!-- Point E -->
    <div>
      <p><b>E.&nbsp;Your Responsability</b></p>
      <div class="privacy-details">
        <p>
          By singing up and using the Service you accept and agree our Privacy Policy. You will indemnify and hold Solutions Experta Inc. and its affiliates, agents and employees harmless from and against any and all loss, liability, and expense (including reasonable attorney’s fees) suffered or incurred by reason of any claims, proceedings or suits based on or arising out of the use of the Service.
        </p>
      </div>
    </div>
    <!-- Point F -->
    <div>
      <p><b>F.&nbsp;Cancellations & Terminations</b></p>
      <div class="privacy-details">
        <p>
          You are solely responsible for the proper cancellation of your account.
          You can cancel your subscription plan at any time by accessing the “Subscription and Billing” page and clicking the “Cancel Subscription” link following the on-screen instructions.
          Alternatively can you Delete your account, this will trigger an automatic cancellation of your subscription plan.
          Any other way of communicating cancellations is not considered.
          There will be no refunds if you cancel the Service before the end of your current, paid-up month, and you will not be charged thereafter.
          There is no cancellation fee. Cancellation is immediate.
          If you delete your account all of your Content will be immediately deleted from our records.
        </p>
        <p>
          The TOS constitutes the entire agreement between you and Solutions Experta Inc. and governs your use of the Service.
        </p>
      </div>
    </div>
    <br>
    <p>
      <b>Last updated on March 3th, 2022</b>
    </p>
  </div>
  <!-- end of Terms & Conditions -->
</div>
