import { Component, OnInit } from '@angular/core';
import { WizardBudgetPlannerService } from "../../../WizardBudgetPlannerService";
import {WizardDataSingleton} from "../../../WizardDataSingleton";

@Component({
  selector: 'termsConditions',
  styleUrls: [
    '../../../../assets/landing/css/style.scss'
  ],
  templateUrl: './termsConditions.component.html'
})
export class TermsConditionsComponent implements OnInit {

  public sglCommonData: WizardDataSingleton = null;

  public selectedPlan: string = 'BSC';
  public aimAgree: boolean = false;

  constructor(private bpService:WizardBudgetPlannerService) { }

  async ngOnInit() {
    await this.bpService.init();
    this.sglCommonData = WizardDataSingleton.getInstance(this.bpService);
  }

}
