import * as i0 from '@angular/core';
import { Injectable, Component, Directive, NgModule } from '@angular/core';
import { NG_ASYNC_VALIDATORS } from '@angular/forms';
import { debounceTime, map } from 'rxjs';
import * as i1 from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import * as i2 from 'angular-oauth2-oidc';
import { NullValidationHandler } from 'angular-oauth2-oidc';
class BudgetplannerUiCommonService {
  constructor() {}
  static ɵfac = function BudgetplannerUiCommonService_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || BudgetplannerUiCommonService)();
  };
  static ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
    token: BudgetplannerUiCommonService,
    factory: BudgetplannerUiCommonService.ɵfac,
    providedIn: 'root'
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(BudgetplannerUiCommonService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [], null);
})();
class BudgetplannerUiCommonComponent {
  constructor() {}
  ngOnInit() {}
  static ɵfac = function BudgetplannerUiCommonComponent_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || BudgetplannerUiCommonComponent)();
  };
  static ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
    type: BudgetplannerUiCommonComponent,
    selectors: [["lib-budgetplanner-ui-common"]],
    decls: 2,
    vars: 0,
    template: function BudgetplannerUiCommonComponent_Template(rf, ctx) {
      if (rf & 1) {
        i0.ɵɵelementStart(0, "p");
        i0.ɵɵtext(1, " budgetplanner-ui-common works! ");
        i0.ɵɵelementEnd();
      }
    },
    encapsulation: 2
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(BudgetplannerUiCommonComponent, [{
    type: Component,
    args: [{
      selector: 'lib-budgetplanner-ui-common',
      template: `
    <p>
      budgetplanner-ui-common works!
    </p>
  `
    }]
  }], () => [], null);
})();
class BudgetPlannerServiceBase {
  http;
  oauthService;
  httpClient;
  reqHeader = {};
  BpConfigs;
  ipAllowed = false;
  sourceName;
  CurrentPlans = [];
  applicationRoot = "";
  constructor(http, oauthService) {
    this.http = http;
    this.oauthService = oauthService;
    this.httpClient = http;
    this.sourceName = "invalid";
  }
  async init() {
    await this.configure();
    await this.oauthService.tryLoginImplicitFlow();
  }
  login() {
    this.oauthService.initLoginFlow();
  }
  logout() {
    this.getDirect("/api/home/signOut").then(() => {
      this.oauthService.logOut();
      window.location.href = this.BpConfigs.landingUrl;
    });
  }
  get accessToken() {
    var authToken = this.oauthService.getAccessToken();
    return authToken;
  }
  getAuthHeader(contentType = 'application/json') {
    return new HttpHeaders({
      'Content-Type': contentType,
      'Authorization': 'Bearer ' + this.accessToken
    });
  }
  get claims() {
    let claims = this.oauthService.getIdentityClaims();
    if (claims !== null) {
      let test = claims;
      let expirationTime = test.exp;
      let currentEpochTime = new Date().getTime() / 1000 - 100;
      if (currentEpochTime >= expirationTime) {
        this.oauthService.logOut();
        this.login();
      }
    }
    return claims;
  }
  async configure() {
    let oauthConfigs = await this.getDirect("/api/Config/GetOidcConfigs");
    if (oauthConfigs !== null) {
      this.ipAllowed = true;
      oauthConfigs.webAuthOidc.redirectUri = window.location.origin + this.applicationRoot + "/";
      this.oauthService.configure(oauthConfigs.webAuthOidc);
      this.oauthService.tokenValidationHandler = new NullValidationHandler();
      await this.oauthService.loadDiscoveryDocument(oauthConfigs.webAuthOidc.documentDiscovery);
      this.BpConfigs = await this.getDirect("/api/Config/GetBpConfigs");
    } else {
      this.ipAllowed = false;
    }
  }
  static getCookie(name) {
    let ca = document.cookie.split(';');
    let caLen = ca.length;
    let cookieName = `${name}=`;
    let c;
    for (let i = 0; i < caLen; i += 1) {
      c = ca[i].replace(/^\s+/g, '');
      if (c.indexOf(cookieName) == 0) {
        return c.substring(cookieName.length, c.length);
      }
    }
    return '';
  }
  async reloadSessionData() {
    await this.getDirect("/api/session/clear");
  }
  reloadData(ctrl, identifier) {
    return this.loadInto('/api/' + ctrl + '/Get/' + identifier);
  }
  SyncSessionAccessToken() {
    return this.getDirect("/api/Home/SetupSession");
  }
  getCurrentCompany() {
    return this.getDirect("/api/Home/GetCurrentCompany").then(result => {
      return result;
    });
  }
  refreshCurrentCompany() {
    return this.getDirect("/api/Home/RefreshCurrentCompany");
  }
  getAuthCompany() {
    return this.getDirect("/api/Home/GetAuthCompany");
  }
  getCurrentNewCompany() {
    return this.getDirect("/api/Home/GetCurrentNewCompany");
  }
  getAccessibleCompanies() {
    return this.getDirect("/api/Home/GetAccessibleCompanies");
  }
  selectPlan(companyIdentifier) {
    let accessToken = sessionStorage.getItem("access_token");
    let wizCook = {
      at: accessToken,
      i: companyIdentifier
    };
    this.setCookie("bp.wizardtransfert", JSON.stringify(wizCook), 1, '/', this.BpConfigs.cookieDomain);
    window.location.href = `${this.BpConfigs.wizardUrl}/p`;
  }
  changeCurrentCompany(companyIdentifier, orgReady) {
    if (!orgReady) {
      let accessToken = sessionStorage.getItem("access_token");
      let wizCook = {
        at: accessToken,
        i: companyIdentifier
      };
      this.setCookie("bp.wizardtransfert", JSON.stringify(wizCook), 1, '/', this.BpConfigs.cookieDomain);
      window.location.href = `${this.BpConfigs.wizardUrl}/r`;
    } else {
      this.getDirect(`/api/Home/ChangeCurrentCompany/${companyIdentifier}`).then(() => {
        window.location.href = `${this.applicationRoot}/admin`;
      });
    }
  }
  setCookie(name, value, expireDays, path = '', domain) {
    let d = new Date();
    d.setTime(d.getTime() + expireDays * 24 * 60 * 60 * 1000);
    let expires = `expires=${d.toUTCString()}`;
    let cpath = path ? `; path=${path}` : '';
    document.cookie = `${name}=${value};domain=${domain};${expires}${cpath}`;
  }
  searchData(ctrl, dataFilters = {}) {
    let url = window.location.origin + this.applicationRoot + ctrl;
    return this.httpClient.post(url, dataFilters, {
      headers: this.getAuthHeader()
    }).toPromise();
  }
  getFile(ctrl) {
    let url = window.location.origin + this.applicationRoot + ctrl;
    return this.httpClient.get(url, {
      responseType: 'blob',
      headers: this.getAuthHeader('application/octet-stream')
    });
  }
  getDirect(ctrl) {
    let url = window.location.origin + this.applicationRoot + ctrl;
    return this.httpClient.get(url, {
      headers: this.getAuthHeader()
    }).toPromise();
  }
  httpGet(ctrl) {
    let url = window.location.origin + this.applicationRoot + ctrl;
    return this.httpClient.get(url, {
      headers: this.getAuthHeader()
    });
  }
  deleteDirect(ctrl) {
    let url = window.location.origin + this.applicationRoot + ctrl;
    return this.httpClient.delete(url, {
      headers: this.getAuthHeader()
    }).toPromise();
  }
  changeValidation(ctrl, elm) {
    let url = window.location.origin + this.applicationRoot + ctrl;
    return this.httpClient.post(url, elm, {
      headers: this.getAuthHeader()
    }).toPromise();
  }
  postDirect(ctrl, scope, fct) {
    let url = window.location.origin + this.applicationRoot + ctrl;
    this.httpClient.post(url, scope.currentEntity, {
      headers: this.getAuthHeader()
    }).subscribe(result => {
      if (result.success) {
        scope.errors = [];
        fct(result);
      } else {
        scope.errors = result.errors;
      }
    }, error => console.log(error));
  }
  post(ctrl, elm) {
    let url = window.location.origin + this.applicationRoot + ctrl;
    return this.httpClient.post(url, elm, {
      headers: this.getAuthHeader()
    }).toPromise();
  }
  postFile(ctrl, file) {
    let data = new FormData();
    data.append(file.name, file);
    let url = window.location.origin + this.applicationRoot + ctrl;
    let customHeader = new HttpHeaders({
      'Authorization': 'Bearer ' + this.accessToken
    });
    return this.httpClient.post(url, data, {
      headers: customHeader
    }).toPromise();
  }
  postFiles(ctrl, files) {
    let data = new FormData();
    files.forEach(f => {
      data.append(f.name, f);
    });
    let url = window.location.origin + this.applicationRoot + ctrl;
    let customHeader = new HttpHeaders({
      'Authorization': 'Bearer ' + this.accessToken
    });
    return this.httpClient.post(url, data, {
      headers: customHeader
    }).toPromise();
  }
  deleteEntity(ctrl, scope, fct) {
    let url = window.location.origin + this.applicationRoot + ctrl + '/' + scope.currentEntity.identifier;
    this.httpClient.delete(url, {
      headers: this.getAuthHeader()
    }).subscribe(result => {
      fct(result);
      /*
            if(result.success) {
              scope.errors = [];
              fct(result);
            } else {
              console.log(result);
              scope.errors = result.errors;
            }
      */
    }, error => console.log(error));
  }
  addCreditBalance(fromIdentifier, to) {
    let url = `${window.location.origin}${this.applicationRoot}/api/creditbill/${fromIdentifier}/addcreditbalance`;
    return this.httpClient.post(url, to, {
      headers: this.getAuthHeader()
    }).toPromise();
  }
  getUnPaidCreditNote(vendorId, issuedDate) {
    let url = `${window.location.origin}${this.applicationRoot}/api/creditbill/getunpaidcreditnote/${vendorId}/${issuedDate}?${+new Date().toISOString()}`;
    return this.httpClient.get(url, {
      headers: this.getAuthHeader()
    }).toPromise();
  }
  submitContent(ctrl, scope, fct) {
    let url = ctrl;
    if (scope.currentEntity.identifier != null) {
      url += "edit";
    } else {
      url += "createnew";
    }
    this.httpClient.post(window.location.origin + this.applicationRoot + url, scope.currentEntity, {
      headers: this.getAuthHeader()
    }).subscribe(result => {
      if (result.success) {
        scope.currentEntity = result.data;
        scope.errors = [];
        fct();
      } else {
        scope.errors = result.errors;
      }
    }, error => {
      console.error(error);
    });
  }
  generateFile(url, parms) {
    return this.httpClient.post(this.applicationRoot + url, parms, {
      responseType: 'blob',
      headers: this.getAuthHeader()
    });
  }
  viewFile(url) {
    return this.httpClient.get(this.applicationRoot + url, {
      responseType: 'blob',
      headers: this.getAuthHeader('application/octet-stream')
    });
  }
  getCustomersList() {
    let filters = {
      isArchived: null
    };
    return this.loadIntoWithFilters('/api/customer/search', filters);
  }
  loadTimezonesList() {
    return this.loadInto('/api/system/timezones');
  }
  getEmployeesList() {
    return this.loadInto('/api/employee');
  }
  loadInto(ctrl) {
    let url = this.applicationRoot + ctrl + '?' + new Date().toISOString();
    return this.httpClient.get(url, {
      headers: this.getAuthHeader()
    }).toPromise();
  }
  loadIntoAsync(ctrl) {
    let url = this.applicationRoot + ctrl + '?' + new Date().toISOString();
    return this.httpClient.get(url, {
      headers: this.getAuthHeader()
    }).toPromise();
  }
  loadIntoWithFilters(ctrl, filters) {
    let url = this.applicationRoot + ctrl + '?' + new Date().toISOString();
    return this.httpClient.post(url, filters, {
      headers: this.getAuthHeader()
    }).toPromise();
  }
  static ɵfac = function BudgetPlannerServiceBase_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || BudgetPlannerServiceBase)(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.OAuthService));
  };
  static ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
    token: BudgetPlannerServiceBase,
    factory: BudgetPlannerServiceBase.ɵfac
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(BudgetPlannerServiceBase, [{
    type: Injectable
  }], () => [{
    type: i1.HttpClient
  }, {
    type: i2.OAuthService
  }], null);
})();
class CompanyNameDirective {
  bpService;
  constructor(bpService) {
    this.bpService = bpService;
    bpService.init().then(() => {});
  }
  validate(control) {
    return this.bpService.httpGet(`/api/CompanyInfo/ValidateCompanyName/${control.value}`).pipe(debounceTime(50), map(data => {
      if (data === false) {
        return {
          'companyNameValidator': {
            value: control.value
          }
        };
      }
      return null;
    }));
  }
  static ɵfac = function CompanyNameDirective_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || CompanyNameDirective)(i0.ɵɵdirectiveInject(BudgetPlannerServiceBase));
  };
  static ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
    type: CompanyNameDirective,
    selectors: [["", "companyNameValidator", ""]],
    features: [i0.ɵɵProvidersFeature([{
      provide: NG_ASYNC_VALIDATORS,
      useExisting: CompanyNameDirective,
      multi: true
    }])]
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CompanyNameDirective, [{
    type: Directive,
    args: [{
      selector: '[companyNameValidator]',
      providers: [{
        provide: NG_ASYNC_VALIDATORS,
        useExisting: CompanyNameDirective,
        multi: true
      }]
    }]
  }], () => [{
    type: BudgetPlannerServiceBase
  }], null);
})();
class CompanyShortNameDirective {
  bpService;
  constructor(bpService) {
    this.bpService = bpService;
    bpService.init().then(() => {});
  }
  validate(control) {
    return this.bpService.httpGet(`/api/CompanyInfo/ValidateShortName/${control.value}`).pipe(debounceTime(50), map(data => {
      if (data === false) {
        return {
          'companyShortNameValidator': {
            value: control.value
          }
        };
      }
      return null;
    }));
  }
  static ɵfac = function CompanyShortNameDirective_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || CompanyShortNameDirective)(i0.ɵɵdirectiveInject(BudgetPlannerServiceBase));
  };
  static ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
    type: CompanyShortNameDirective,
    selectors: [["", "companyShortNameValidator", ""]],
    features: [i0.ɵɵProvidersFeature([{
      provide: NG_ASYNC_VALIDATORS,
      useExisting: CompanyShortNameDirective,
      multi: true
    }])]
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CompanyShortNameDirective, [{
    type: Directive,
    args: [{
      selector: '[companyShortNameValidator]',
      providers: [{
        provide: NG_ASYNC_VALIDATORS,
        useExisting: CompanyShortNameDirective,
        multi: true
      }]
    }]
  }], () => [{
    type: BudgetPlannerServiceBase
  }], null);
})();
class BudgetplannerUiCommonModule {
  static ɵfac = function BudgetplannerUiCommonModule_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || BudgetplannerUiCommonModule)();
  };
  static ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
    type: BudgetplannerUiCommonModule
  });
  static ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({});
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(BudgetplannerUiCommonModule, [{
    type: NgModule,
    args: [{
      declarations: [BudgetplannerUiCommonComponent, CompanyNameDirective, CompanyShortNameDirective],
      imports: [],
      exports: [BudgetplannerUiCommonComponent, CompanyNameDirective, CompanyShortNameDirective]
    }]
  }], null, null);
})();
const WEEKDAYS = [{
  value: 0,
  text: 'Sunday'
}, {
  value: 1,
  text: 'Monday'
}, {
  value: 2,
  text: 'Tuesday'
}, {
  value: 3,
  text: 'Wednesday'
}, {
  value: 4,
  text: 'Thursday'
}, {
  value: 5,
  text: 'Friday'
}, {
  value: 6,
  text: 'Saturday'
}];
class CommonDataSingleton {
  bpSrv = {};
  organizationTypes = [];
  accountStructureTypes = [];
  accountTypes = [];
  bankAccounts = [];
  taxStatementDefinitions = [];
  employees = [];
  accounts = [];
  assetAccounts = [];
  customersList = [];
  vendorsList = [];
  taxTypes = [];
  taxSystems = [];
  timezonesList = [];
  fiscalCalendars = [];
  fiscalPeriods = [];
  paymentMethods = [];
  unInvoicedTimeEntries = [];
  unpaidEmplExpenses = [];
  projects = [];
  taskLists = [];
  tasks = [];
  countries = [];
  currentCompany = {};
  accessibleCompanies = [];
  currentEmployee = {};
  monthsList = [{
    id: 1,
    name: 'January',
    maxdays: 31
  }, {
    id: 2,
    name: 'February',
    maxdays: 28
  }, {
    id: 3,
    name: 'March',
    maxdays: 31
  }, {
    id: 4,
    name: 'April',
    maxdays: 30
  }, {
    id: 5,
    name: 'May',
    maxdays: 31
  }, {
    id: 6,
    name: 'June',
    maxdays: 30
  }, {
    id: 7,
    name: 'July',
    maxdays: 31
  }, {
    id: 8,
    name: 'August',
    maxdays: 31
  }, {
    id: 9,
    name: 'September',
    maxdays: 30
  }, {
    id: 10,
    name: 'October',
    maxdays: 31
  }, {
    id: 11,
    name: 'November',
    maxdays: 30
  }, {
    id: 12,
    name: 'December',
    maxdays: 31
  }];
  constructor() {}
  clearCommonEntities() {
    this.accountStructureTypes = [];
    this.accountTypes = [];
    this.bankAccounts = [];
    this.taxStatementDefinitions = [];
    this.employees = [];
    this.accounts = [];
    this.assetAccounts = [];
    this.customersList = [];
    this.vendorsList = [];
    this.taxTypes = [];
    this.taxSystems = [];
    this.timezonesList = [];
    this.fiscalCalendars = [];
    this.fiscalPeriods = [];
    this.paymentMethods = [];
    this.unInvoicedTimeEntries = [];
    this.unpaidEmplExpenses = [];
    this.projects = [];
    this.taskLists = [];
    this.tasks = [];
    this.countries = [];
    this.organizationTypes = [];
  }
  reloadCommonEntities() {
    this.reloadCustomers();
    this.bpSrv.loadTimezonesList().then(result => this.timezonesList = result.data);
    this.reloadCountries();
    this.reloadEmployees(undefined);
    this.reloadVendors();
    this.reloadAccountStructureTypes();
    this.reloadAccountTypes();
    this.reloadAccounts();
    this.reloadAssetAccounts();
    this.reloadTaxTypes();
    this.reloadTaxSystems();
    this.reloadFiscalCalendars();
    this.reloadFiscalPeriods();
    this.reloadPaymentMethods();
    this.reloadTaxStatementDefinitions();
    this.reloadBankAccounts();
    this.reloadUnInvoicedTimeEntries();
    this.reloadUnPaidEmplApExpenses();
    this.reloadProjects();
    this.reloadTaskLists();
    this.reloadTasks();
    this.reloadOrganizationTypes();
  }
  reloadCustomers() {
    this.bpSrv.getCustomersList().then(result => this.customersList = result.data);
  }
  loadCurrentUserContextAsync() {
    this.bpSrv.loadIntoAsync('/api/system/currentloginuser').then(res => {
      this.currentEmployee = res.data;
    });
  }
  reloadTaskLists() {
    this.bpSrv.loadInto('/api/tasklist').then(result => {
      this.taskLists = result;
    });
  }
  reloadTasks() {
    this.bpSrv.loadInto('/api/taskelement').then(result => {
      this.tasks = result;
    });
  }
  reloadProjects() {
    this.bpSrv.loadInto('/api/project').then(result => {
      this.projects = result;
    });
  }
  reloadUnPaidEmplApExpenses() {
    this.bpSrv.loadInto('/api/emplexpense/GetPayrollableApInvoice').then(result => {
      this.unpaidEmplExpenses = result.data;
    });
  }
  reloadUnInvoicedTimeEntries() {
    this.bpSrv.loadInto('/api/arinvoice/uninvoiceTimeEntry').then(result => {
      this.unInvoicedTimeEntries = result.data;
    });
  }
  reloadFiscalPeriods() {
    this.bpSrv.loadInto("/api/fiscalcalendarperiod").then(result => {
      this.fiscalPeriods = result;
    });
  }
  reloadBankAccounts() {
    this.bpSrv.loadInto("/api/bankaccount").then(result => this.bankAccounts = result);
  }
  reloadTaxStatementDefinitions() {
    this.bpSrv.loadInto("/api/taxstatementdefinition").then(result => this.taxStatementDefinitions = result);
  }
  reloadVendors() {
    this.bpSrv.loadInto("/api/vendor").then(result => {
      this.vendorsList = result;
    });
  }
  reloadEmployees(fct) {
    this.bpSrv.loadInto("/api/employee").then(result => {
      this.employees = result;
      if (fct !== undefined) {
        fct();
      }
    });
  }
  reloadPaymentMethods() {
    this.bpSrv.loadInto("/api/paymentmethod").then(result => this.paymentMethods = result);
  }
  reloadFiscalCalendars() {
    this.bpSrv.loadInto("/api/fiscalcalendar").then(result => this.fiscalCalendars = result);
  }
  reloadTaxSystems() {
    this.bpSrv.loadInto("/api/taxsystem").then(result => this.taxSystems = result);
  }
  reloadTaxTypes() {
    this.bpSrv.loadInto("/api/TaxType").then(result => this.taxTypes = result);
  }
  reloadAssetAccounts() {
    this.bpSrv.loadInto("/api/AssetAccount").then(result => this.assetAccounts = result);
  }
  reloadAccounts() {
    this.bpSrv.loadInto('/api/Accounts').then(result => this.accounts = result);
  }
  reloadAccountTypes() {
    this.bpSrv.loadInto('/api/AccountType').then(result => this.accountTypes = result);
  }
  reloadAccountStructureTypes() {
    this.bpSrv.loadInto('/api/AccountStructureType').then(result => this.accountStructureTypes = result);
  }
  reloadCountries() {
    this.bpSrv.loadInto('/api/Country').then(result => this.countries = result);
  }
  reloadOrganizationTypes() {
    this.bpSrv.loadInto('/api/OrganizationType').then(result => this.organizationTypes = result);
  }
  static ɵfac = function CommonDataSingleton_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || CommonDataSingleton)();
  };
  static ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
    token: CommonDataSingleton,
    factory: CommonDataSingleton.ɵfac
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CommonDataSingleton, [{
    type: Injectable
  }], () => [], null);
})();

/*
 * Public API Surface of budgetplanner-ui-common
 */

/**
 * Generated bundle index. Do not edit.
 */

export { BudgetPlannerServiceBase, BudgetplannerUiCommonComponent, BudgetplannerUiCommonModule, BudgetplannerUiCommonService, CommonDataSingleton, CompanyNameDirective, CompanyShortNameDirective, WEEKDAYS };
