import {Component, Input, OnDestroy, Inject, ViewEncapsulation} from '@angular/core';
import {Spinkit} from './actionSpinkits';
import {Router, NavigationStart, NavigationEnd, NavigationCancel, NavigationError} from '@angular/router';
import {DOCUMENT} from '@angular/common';

@Component({
    selector: 'action-spinner',
    templateUrl: './actionSpinner.component.html',
    styleUrls: [
        './actionSpinner.component.scss',
        './actionSpinkit-css/actionSk-line-material.scss'
    ],
    encapsulation: ViewEncapsulation.None
})
export class ActionSpinnerComponent implements OnDestroy {
  @Input() public isSpinnerVisible: boolean = true;
  @Input() public message: string = "";
  @Input() public backgroundColor = '#1dc4e9';
  @Input() public spinner = Spinkit.skLine;

  public Spinkit = Spinkit;

  constructor(private router: Router, @Inject(DOCUMENT) private document: Document) {
        this.router.events.subscribe(event => {
            if (event instanceof NavigationStart) {
                this.isSpinnerVisible = true;
            } else if ( event instanceof NavigationEnd || event instanceof NavigationCancel || event instanceof NavigationError) {
                this.isSpinnerVisible = false;
            }
        }, () => {
            this.isSpinnerVisible = false;
        });
    }

    ngOnDestroy(): void {
        this.isSpinnerVisible = false;
    }
}
