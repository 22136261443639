import {Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'trueFalse',
  templateUrl: './trueFalse.component.html',
})
export class trueFalseComponent implements OnInit {
  @Input() currentValue?:boolean;

  constructor() {}

  ngOnInit() {

  }

}
