import {Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'validationState',
  templateUrl: './validated.component.html',
})
export class validatedComponent implements OnInit {
  @Input() currentEntity?:any;

  constructor() {}

  ngOnInit() {

  }

}
