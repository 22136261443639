import {Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'archived-icon',
  templateUrl: './archivedIcon.component.html',
})
export class archivedIconComponent implements OnInit {
  @Input() model?:any;

  constructor() {}

  ngOnInit() {

  }

}
