import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { actionButton } from './actionButton.component';
import { archivedIconComponent } from "./archivedIcon.component";
import { trueFalseComponent } from "./trueFalse.component";
import {validatedComponent} from "./validated.component";

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [
    actionButton, archivedIconComponent, trueFalseComponent, validatedComponent
  ],
  exports: [
    actionButton, archivedIconComponent, trueFalseComponent, validatedComponent
  ],
  providers: [

  ]
})
export class bpWidgetsModule { }
