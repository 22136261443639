import { Injectable } from "@angular/core";
import { HttpClient } from '@angular/common/http';
import { OAuthService } from 'angular-oauth2-oidc';
import {
  CompanyInfo, Plan
} from "@expertasolutions/budgetplanner-ui-common";

import { BudgetPlannerServiceBase } from "@expertasolutions/budgetplanner-ui-common";

@Injectable()
export class WizardBudgetPlannerService extends BudgetPlannerServiceBase {
  private readonly budgetPlannerCookie: any;
  public CurrentPlans: Plan[] = [];

  public intentional: boolean = false;

  constructor(public http: HttpClient, public oauthService: OAuthService) {
    super(http, oauthService);
    this.applicationRoot = "";
    let cookieContent = BudgetPlannerServiceBase.getCookie("bp.wizardtransfert");
    this.budgetPlannerCookie = JSON.parse(cookieContent);
  }

  public async init() {
    if(this.intentional) {
      await this.configure();
    }
  }

  protected async configure() {
    this.BpConfigs = await this.getDirect("/api/Config/GetBpConfigs");
    this.CurrentPlans = await this.getDirect("/api/Config/GetSystemPlans");
  }

  public get accessToken() {
    return this.budgetPlannerCookie.at;
  }

  public changeCurrentCompany() {
    return this.getDirect(`/api/Home/ChangeCurrentCompany/${this.budgetPlannerCookie.i}`);
  }

  public abort() {
    this.getDirect('/api/Home/Abort').then(() => {
      if(this.budgetPlannerCookie.src === 'portal') {
        window.location.href = this.BpConfigs.portalUrl;
      } else {
        window.location.href = this.BpConfigs.landingUrl;
      }
    });
  }

  public transfertToAdminPortal(companyInfo: CompanyInfo) {
    this.getDirect(`/api/Home/ChangeCurrentCompany/${companyInfo.identifier}`).then(() => {
      window.location.href = this.BpConfigs.wizardTransfertPortalUrl;
    });
  }
}
