<a href="javascript:void(0);"
        (click)="showDocument()"
        *ngIf="hasDocument">
  <i class="feather icon-paperclip bpDocument fa-lg"
     [ngClass]="{ 'text-success': model.summary.documentValidationState === 2, 'text-warning': model.summary.documentValidationState === 1, 'text-danger': model.summary.documentValidationState === 0 }"></i>
</a>&nbsp;

<a href="javascript:void(0);" (click)="edit()" *ngIf="hasEdit">
  <i class="feather icon-edit fa-lg"
     [ngClass]="{ 'bpEdit': (!model.validated && !parentIsValidated) || parentIsValidated == null, 'bpEditDisabled': (model.validated || parentIsValidated) && parentIsValidated != null}"></i>
</a>&nbsp;

<a href="javascript:void(0);" (click)="delete()" *ngIf="hasDelete">
  <i class="feather icon-trash fa-lg"
     [ngClass]="{ 'bpDelete': !model.validated && !parentIsValidated, 'bpDeleteDisabled': model.validated || parentIsValidated }"></i>
</a>
