import {Injectable} from "@angular/core";

import {CommonDataSingleton, ICommonDataSingleton} from "@expertasolutions/budgetplanner-ui-common";
import {WizardBudgetPlannerService} from "./WizardBudgetPlannerService";

@Injectable()
export class WizardDataSingleton extends CommonDataSingleton<WizardBudgetPlannerService> {

  public bpSrv: WizardBudgetPlannerService = {} as WizardBudgetPlannerService;
  public static _instance: WizardDataSingleton = new WizardDataSingleton();

  constructor() {
    super();
    WizardDataSingleton._instance = this;
  }

  public static getInstance(bpService: WizardBudgetPlannerService, initialLoad: boolean = false) : WizardDataSingleton {
    let instance = WizardDataSingleton._instance;
    instance.bpSrv = bpService;
    if(initialLoad) {
      instance.reloadCommonEntities();
    }
    return instance;
  }

  public clearCommonEntities() {
    super.clearCommonEntities();
  }

  public reloadCommonEntities() {
    this.bpSrv.loadTimezonesList().then(result => this.timezonesList = result.data);
    this.reloadCountries();
    this.reloadEmployees(undefined);
    this.reloadVendors();
    this.reloadTaxTypes();
    this.reloadTaxSystems();
    this.reloadFiscalCalendars();
    this.reloadFiscalPeriods();
    this.reloadOrganizationTypes();
  }
}
